import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries]
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import SwiperCore, { Pagination } from 'swiper'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Interface
import { ReferencesProps } from './ReferencesShell'
import Grid from './Layout/Grid'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

SwiperCore.use([Pagination])

const Highlighted: React.FC<ReferencesProps> = ({ fields }) => {
  const {
    allWpReference,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedReferencesQueryQuery>(graphql`
    query highlightedReferencesQuery {
      allWpReference {
        edges {
          node {
            databaseId
            title
            recap {
              delivery
              scope
              shipyard
              owner
            }
            referencecategories {
              nodes {
                databaseId
                name
              }
            }
          }
        }
      }
    }
  `)

  const references: unknown = allWpReference.edges

  const postIds = fields.references?.map((reference) => reference?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={references as BlogBinkPosts}
        id="highlighted-references"
        limit={Infinity}
      >
        <div className="py-5">
          <Grid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Highlighted
